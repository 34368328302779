import { useQuery } from "react-query";
import api from "../api";

export const useGetBookRequestForUser = (status) => {
  return useQuery(["reservation-request-for-user", status], () => {
    const queryParams = {};

    if (status !== "ALL") {
      queryParams.status = status;
    }

    return api
      .get("user/reservation", { params: queryParams })
      .then((res) => res.data);
  });
};

export const useUserRemoveBookRequest = (id) => {
  return api.put(`/user/reservation/${id}`).then((res) => res.data);
};
