import React, { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import AdminSellRequest from "./AdminSellRequest";
import AdminWithdrawRequestOfUser from "./AdminWithdrawRequestOfUser";
import AdminAcceptWireRequest from "../AdminAcceptWireRequest";
import AdminManualKycVerify from "./AdminManualKycVerify";
import ReservationTable from "./ReservationTable";

const AdminRequest = () => {
  const [activeTab, setActiveTab] = useState("token");

  const handleNavClick = (tab) => {
    setActiveTab(tab);
  };

  let pageContent;
  switch (activeTab) {
    case "token":
      pageContent = <AdminSellRequest />;
      break;
    case "withdraw":
      pageContent = <AdminWithdrawRequestOfUser />;
      break;
    case "wire":
      pageContent = <AdminAcceptWireRequest />;
      break;
    case "kyc":
      pageContent = <AdminManualKycVerify />;
      break;
    case "vacation":
      pageContent = <ReservationTable />;
      break;
    default:
      pageContent = <AdminSellRequest />;
      break;
  }

  return (
    <div className="container">
      <Navbar
        bg="light"
        className="overflow-y-hidden mt-5 p-4"
        style={{ height: "40px", zIndex: "0" }}
      >
        <Navbar.Brand>Request Page</Navbar.Brand>
        <Nav className="ml-auto">
          {" "}
          {/* Changed class here */}
          <Nav.Link
            className={
              activeTab === "token"
                ? "text-carre-blue border-bottom border-2 border-carre-blue"
                : ""
            }
            onClick={() => handleNavClick("token")}
          >
            Share
          </Nav.Link>
          <Nav.Link
            className={
              activeTab === "withdraw"
                ? "text-carre-blue border-bottom border-2 border-carre-blue"
                : ""
            }
            onClick={() => handleNavClick("withdraw")}
          >
            Withdraw
          </Nav.Link>
          <Nav.Link
            className={
              activeTab === "wire"
                ? "text-carre-blue border-bottom border-2 border-carre-blue"
                : ""
            }
            onClick={() => handleNavClick("wire")}
          >
            Wire
          </Nav.Link>
          <Nav.Link
            className={
              activeTab === "kyc"
                ? "text-carre-blue border-bottom border-2 border-carre-blue"
                : ""
            }
            onClick={() => handleNavClick("kyc")}
          >
            Manual Kyc
          </Nav.Link>
          <Nav.Link
            className={
              activeTab === "vacation"
                ? "text-carre-blue border-bottom border-2 border-carre-blue"
                : ""
            }
            onClick={() => handleNavClick("vacation")}
          >
            Vacation Book
          </Nav.Link>
        </Nav>
      </Navbar>

      <div className="container mt-4">{pageContent}</div>
    </div>
  );
};

export default AdminRequest;
