import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Card,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  useCreateVacationOrder,
  useGetVacationDetails,
  useGetVacationOrderBook,
  useGetVacationOrderDetails,
} from "../../../hooks/useGetVacationDetails";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import VacationOrderBook from "./VacationOrderBook";
import { useGetAppConfig } from "../../../hooks/useGetAppConfig";
import { useGetAccount } from "../../../hooks/useGetAccount";
import GoBackButton from "../../../components/GoBackButton";
import ConfirmModal from "../ConfirmModal";
import moment from "moment/moment";
import TransferModal from "../OrderDataInfo";
import VacationTransferModal from "./VacationTransfer";
import { useTabContext } from "../TabContext";
import RangeDatePicker from "../../../components/RangeDatePicker"; // Import the mutation hook

function VacationForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const queryClient = useQueryClient();
  const [transferData, setTransferData] = useState({});

  const { mutate, isLoading } = useMutation(useCreateVacationOrder, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("vacation-order-details");
      queryClient.invalidateQueries("vacation-transfer");
      queryClient.invalidateQueries("vacation-orders");
      queryClient.invalidateQueries("vacation");
      queryClient.invalidateQueries("vacation-order-book");
      toast.success("Successfully");
      setShowTransferModal(true);
      setConfirmModalOpen(false);
      setTransferData(data);
    },
    onError: (error) => {
      toast.error(error?.response.data.error);
    },
  });
  const { type } = useParams();
  const { data: config } = useGetAppConfig();
  const { data: vacationAccount, error, refetch } = useGetVacationDetails();
  const { data: account } = useGetAccount();
  const { data: orderDetails } = useGetVacationOrderDetails();
  const { data: orders } = useGetVacationOrderBook();

  const handleConfirmPurchase = () => {
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    setValue("account_id", account?.id);
    setValue("type", type);
  }, [type]);

  useEffect(() => {
    setValue("vacation_id", vacationAccount?.id);
  }, [vacationAccount]);

  useEffect(() => {
    setValue("point_price", orderDetails?.point_price);
  }, [orderDetails]);
  // Set default values for vacation_id, account_id, and type
  const onSubmit = (data) => {
    const newData = {
      ...data,
      end_period: data.end_period === "until_completed" ? "" : data.end_period,
    };
    mutate(newData);
  };

  const points = watch("points");
  const price = watch("point_price");
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const { setDepositModalOpen } = useTabContext();

  // Handle opening and closing the ConfirmModal
  const openConfirmModal = () => {
    setConfirmModalOpen(true);
  };
  const handleCloseModal = () => {
    setShowTransferModal(false);
  };

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };
  const highestBid = orders
    ?.filter((order) => order.type === "BUY")
    .reduce(
      (highest, order) => {
        return order.point_price > highest.point_price ? order : highest;
      },
      { point_price: -Infinity }
    ) || { point_price: "No data" };

  const lowestAsk = orders
    ?.filter((order) => order.type === "SELL")
    .reduce(
      (lowest, order) => {
        return order.point_price < lowest.point_price ? order : lowest;
      },
      { point_price: Infinity }
    ) || { point_price: "No data" };
  const secondaryData = (
    <div>
      <div className="fs-lg fw-semibold">
        {type === "BUY" ? "Buying" : "Selling"} {points} points at{" "}
        {price?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}{" "}
        per point or {type === "BUY" ? "lower" : "higher"}
      </div>

      <div className="fw-semibold my-2 w-100 fs-md d-flex justify-content-between">
        <div className="flex-grow-0">Account:</div>
        <div className="flex-grow-1 text-end">
          Carré <span>($ {account?.balance.toLocaleString()})</span>
        </div>
      </div>
      <div className="fw-semibold my-2 w-100 fs-md d-flex justify-content-between">
        <div className="flex-grow-0">Estimated Fees:</div>
        <div className="flex-grow-1 text-end">
          {(
            (points *
              price *
              (type === "BUY"
                ? config?.vacation_buy_fee || 0
                : config?.vacation_sell_fee || 0)) /
            100
          )?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </div>
      </div>
      <div className="fw-semibold my-2 w-100 fs-md d-flex justify-content-between">
        <div className="flex-grow-0">Estimated Order Value:</div>
        <div className="flex-grow-1 text-end">
          {(
            points *
            price *
            (type === "BUY"
              ? 1 + (config?.vacation_buy_fee || 0) / 100
              : 1 - (config?.vacation_sell_fee || 0) / 100)
          )?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </div>
      </div>

      <>
        <div className="fw-semibold my-2 w-100 fs-md d-flex justify-content-between">
          <div className="flex-grow-0">Ask:</div>
          <div className="flex-grow-1 text-end">
            {lowestAsk.point_price !== Infinity
              ? `${
                  lowestAsk.available_points
                } X ${lowestAsk.point_price?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}`
              : "No data"}
          </div>
        </div>
        <div className="fw-semibold my-2 w-100 fs-md d-flex justify-content-between">
          <div className="flex-grow-0">Bid:</div>
          <div className="flex-grow-1 text-end">
            {highestBid.point_price !== -Infinity
              ? `${
                  highestBid.available_points
                } X ${highestBid.point_price.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}`
              : "No data"}
          </div>
        </div>

        <div className="fw-semibold my-2 w-100 fs-md d-flex justify-content-between">
          <div className="flex-grow-0">Last:</div>
          <div className="flex-grow-1 text-end">
            {orderDetails?.point_price?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </div>
        </div>
      </>

      <div className="mx-1 mt-4">As of {moment().format("MMM D HH:mm")}</div>
    </div>
  );
  return (
    <Container className="mb-4 mt-2">
      <GoBackButton />
      <VacationOrderBook />
      <Row>
        <Col>
          <div className="d-inline-block">
            <h6 className="text-muted">Current Number Of Points</h6>
            <h6 className="text-carre-blue">
              {vacationAccount?.balance.toLocaleString()}
            </h6>
          </div>
        </Col>
        <Col>
          <div className="d-inline-block">
            <h6 className="text-muted">Current Position Value</h6>
            <h6 className="text-carre-blue">
              {(
                vacationAccount?.balance * orderDetails?.point_price
              ).toLocaleString("en-US", {
                currency: "USD",
                style: "currency",
              })}
            </h6>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form onSubmit={handleSubmit(openConfirmModal)}>
            {type === "BUY" && (
              <Row className="mt-3">
                <Col lg={8}>
                  <Row>
                    <label className="form-label">Points</label>
                    <div className="col-lg-3 col-md-6 mb-2">
                      <Button
                        size="sm"
                        className="w-100 fw-normal overflow-hidden"
                        onClick={() => setValue("points", 5)}
                        variant="outline-carre-blue"
                        active={points === 5}
                      >
                        <div style={{ textAlign: "center" }}>
                          <div>5 points</div>
                          <div>
                            {(5 * price)?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </div>
                        </div>
                      </Button>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-2">
                      <Button
                        size="sm"
                        className="w-100 fw-normal overflow-hidden"
                        onClick={() => setValue("points", 10)}
                        variant="outline-carre-blue"
                        active={points === 10}
                      >
                        <div style={{ textAlign: "center" }}>
                          <div>10 points</div>

                          {(10 * price)?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </div>
                      </Button>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-2">
                      <Button
                        size="sm"
                        className="w-100 fw-normal overflow-hidden"
                        onClick={() => setValue("points", 25)}
                        variant="outline-carre-blue"
                        active={points === 25}
                      >
                        <div style={{ textAlign: "center" }}>
                          <div>25 points</div>

                          {(25 * price)?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </div>
                      </Button>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-2">
                      <Button
                        size="sm"
                        className="w-100 fw-normal overflow-hidden"
                        onClick={() => setValue("points", 50)}
                        variant="outline-carre-blue"
                        active={points === 50}
                      >
                        <div style={{ textAlign: "center" }}>
                          <div>50 points</div>
                          {(50 * price).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </div>
                      </Button>
                    </div>
                  </Row>
                </Col>
                <Col lg={4}>
                  <Row className="mt-0 mt-lg-4 mb-2">
                    <div style={{ marginTop: 7 }}>
                      <Form.Group controlId="points w-100">
                        <Form.Control
                          size="sm"
                          min={0}
                          defaultValue={10}
                          type="number"
                          {...register("points", {
                            required: true,
                            valueAsNumber: true,
                          })}
                        />
                        <Form.Text className="text-muted h-50 w-100">
                          Custom amount
                        </Form.Text>
                        {errors.points && <span>This field is required</span>}
                      </Form.Group>
                    </div>
                  </Row>
                </Col>
              </Row>
            )}
            {type === "SELL" && (
              <Row className="align-items-center">
                <Col md={8}>
                  <Form.Group controlId="points" className="w-100">
                    <Form.Label className="form-label">Points</Form.Label>
                    <Form.Control
                      size="sm"
                      min={0}
                      defaultValue={1}
                      type="number"
                      {...register("points", {
                        required: true,
                        valueAsNumber: true,
                      })}
                    />
                    {errors.points && <span>This field is required</span>}
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-1 mt-md-4">
                  <Button
                    variant="primary"
                    size="sm"
                    className="w-100 mt-1"
                    onClick={() => {
                      setValue("points", vacationAccount?.balance);
                    }}
                  >
                    Max Value &nbsp;
                    {vacationAccount?.balance?.toLocaleString()} points
                  </Button>
                </Col>
              </Row>
            )}
            <label htmlFor="commission" className="form-label">
              Price Per Point
            </label>
            <InputGroup size="sm">
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type="number"
                min={0}
                defaultValue={orderDetails?.point_price}
                step="any"
                {...register("point_price", {
                  required: true,
                  valueAsNumber: true,
                })}
              />
            </InputGroup>
            <Form.Group>
              <label className="form-label">Payment method</label>
              <Row className="justify-content-between ">
                <Col md={type === "BUY" ? 8 : 12}>
                  <Form.Select
                    size="sm"
                    className={
                      errors.account_id
                        ? "text-carre-blue form-control border-carre-red mb-2"
                        : " form-control false"
                    }
                    {...register("account_id", {
                      required: true,
                    })}
                    disabled
                  >
                    <option key={1} value={account?.id}>
                      Carré Account{" "}
                      {account?.balance?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </option>
                  </Form.Select>
                </Col>
                {type === "BUY" && (
                  <Col md={4}>
                    <Button
                      size="sm"
                      className="w-100 mt-3 mt-md-0 fw-semibold"
                      onClick={() => {
                        setDepositModalOpen(true);
                      }}
                      variant="outline-carre-blue"
                    >
                      Deposit
                    </Button>
                  </Col>
                )}
              </Row>
              {errors.account_id && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
            <Form.Group controlId="end_period">
              <Form.Label>Order good until</Form.Label>
              <Form.Control
                size="sm"
                as="select"
                {...register("end_period", {
                  required: true,
                })}
              >
                <option value="until_completed">Canceled</option>
                <option value="END_OF_DAY">End of Day</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="type">
              <Form.Label>Type</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                defaultValue={type}
                disabled
              />
            </Form.Group>
            <label htmlFor="commission" className="form-label">
              Estimated Fees
            </label>
            <InputGroup size="sm">
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type="text"
                id="commission"
                name="commission"
                value={(
                  points *
                  price *
                  ((type === "BUY"
                    ? config?.vacation_buy_fee || 0
                    : config?.vacation_sell_fee || 0) /
                    100)
                )?.toLocaleString()}
                disabled
                readOnly
              />
            </InputGroup>
            <label htmlFor="commission" className="form-label">
              Total Amount
            </label>
            <InputGroup size="sm">
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type="text"
                id="commission"
                name="commission"
                value={(
                  points *
                    price *
                    (type === "BUY"
                      ? 1 + (config?.vacation_buy_fee || 0) / 100
                      : 1 - (config?.vacation_sell_fee || 0) / 100) || 0
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                disabled
                readOnly
              />
            </InputGroup>

            <Button
              variant="primary"
              size="sm"
              className="mt-2 w-100"
              onClick={() => setConfirmModalOpen(true)}
              disabled={isLoading}
            >
              {isLoading
                ? type === "BUY"
                  ? "Buying"
                  : "Selling"
                : type === "BUY"
                ? "Buy Points"
                : "Sell Points"}
            </Button>
          </Form>
        </Col>
      </Row>

      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={closeConfirmModal}
        onConfirm={handleConfirmPurchase}
        disableButton={isLoading}
        confirmButtonText={isLoading ? "Please Wait" : "Confirm"}
        title={`${type === "BUY" ? "Buy " : "Sell"} Order Review`}
      >
        <div className="mt-2">{secondaryData}</div>
      </ConfirmModal>

      <VacationTransferModal
        transferData={transferData}
        show={showTransferModal}
        isSell={type === "SELL"}
        handleClose={handleCloseModal}
      />
    </Container>
  );
}

export default VacationForm;
