import React, {useState} from "react";
import {useMutation} from "react-query";

import {createUser} from "../hooks/CreateUser";
import {useForm} from "react-hook-form";
import {Button, Form, Modal, Nav} from "react-bootstrap";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


function User() {

    const [show, setShow] = useState(false);
    const notify = (data) => toast(data);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const {mutate, isError, error} = useMutation(createUser, {
        onSuccess: (data) => {
            setResponse(data);

            handleShow()
        },
    });

    const [response, setResponse] = useState({});
    const {register, handleSubmit} = useForm();
    const onSubmit = (data) => {
        mutate(data);

    };
    const verifyAccount = (data) => {
        axios.get(data).catch(error => {
        });
    }

    return (


        <div className="w-50 text-center  align-items-center align-self-center">

            {
                !isError ? <h1>safe</h1> : <div>
                    {notify(error.code)}
                    <ToastContainer/></div>}
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-4">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control

                        {...register("full_name")}
                        type="string"
                        placeholder="Enter your Full Name"
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                        {...register("username")}
                        type="string"
                        placeholder="Enter your User Name"
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Email address</Form.Label>

                    <Form.Control
                        {...register("email")}
                        type="email"
                        placeholder="Enter your email"
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        {...register("password")}
                        type="password"
                        placeholder="Password"
                        required
                    />
                </Form.Group>

                <Button type="submit">Submit</Button>
            </Form>
            <Nav.Link href="/login">Already have an account!</Nav.Link>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Please Verify Account</Modal.Title>
                </Modal.Header>
                <Modal.Body><Button onClick={verifyAccount(response.URL)}>Verify account</Button></Modal.Body>

            </Modal>
        </div>
    );
}

export default User;
