import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import countryList from "./country-list.json";
import { useNavigate } from "react-router-dom";
import { GetRoleNavigationPath } from "../routeUtils";
import { useGetUserData } from "../hooks/useGetUserInformation";
import { useMutation } from "react-query";
import { useUpdateSteps } from "../hooks/useUpdateSteps";
import { toast } from "react-toastify";
import { useProfileDataKyc } from "../hooks/useProfileDataKyc";
import { renderWidget } from "./DataSpikeWidget";
import { useTabContext } from "../pages/Dashboard/TabContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { PaymentMethod } from "../pages/Dashboard/PaymentMethodeModal";
import { AmericanCitizen as apiAmericanCitizen } from "../hooks/CreateUser";
import { useCookies } from "react-cookie";
import { MdInfoOutline } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { useAuth } from "../auth/TokenCreation";
import { useGetDataSpikeStatus } from "../hooks/getDataSpikeStatus";

const OnboardingModal = () => {
  const { setShowOnboarding, showOnboarding } = useTabContext();
  const roleUrl = GetRoleNavigationPath();
  const nav = useNavigate();
  const onBoarding = localStorage.getItem("onboarding");
  const { data, refetch } = useGetUserData();

  useEffect(() => {
    if (data) {
      if (
        data?.guide_step !== "FINISH" &&
        onBoarding === "true" &&
        data?.guide_step !== "LATER_KYC"
      ) {
        setShowOnboarding(true);
      }
    }
    if (data?.guide_step === "LATER_KYC") {
      setShowOnboarding(false);
    }
  }, [onBoarding, data]);

  // const [show, setShow] = useState(data?.kyc_verify === false);
  const [currentStep, setCurrentStep] = useState(1);
  const [verificationId, setVerificationId] = useState();
  const [dataSpikeVerificationId, setDataSpikeVerificationId] = useState();
  const [dataSpikeErrorMessage, setDataSpikeErrorMessage] = useState(undefined);
  const [kycFailedMessage, setKycFailedMessage] = useState(undefined);
  const handleError = (errorMessage) => {
    setVerificationId(undefined);
    setShowVerificationWidget(false);
    setDataSpikeVerificationId(undefined);
    toast.error(errorMessage);
    setDataSpikeErrorMessage(errorMessage);
    setKycFailedMessage(
      "We encountered an issue verifying your identity. Please review the details and try again."
    );
  };

  const handleSuccess = () => {
    toast.success("Congratulations, your identity has been verified!");
    setVerificationId(undefined);
    setDataSpikeVerificationId(undefined);
    setShowVerificationWidget(false);
    setDataSpikeErrorMessage(undefined);
    setKycFailedMessage(undefined);
    refetch();
  };

  // Use the custom hook with the error callback
  const { data: dataSpikeStatus, refetch: refetchDataSpikeStatus } =
    useGetDataSpikeStatus(
      dataSpikeVerificationId,
      handleError, // Pass the error callback
      handleSuccess
    );

  const [showVerificationWidget, setShowVerificationWidget] = useState(false);
  const { control, handleSubmit, formState, watch, setValue } = useForm();
  const { errors } = formState;
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [cookie, setCookies, removeCookie] = useCookies();
  const mutation = useMutation((data) => apiAmericanCitizen(data), {
    onSuccess: (res) => {
      setCookies("american_citizen", res, {
        expires: new Date(Date.now() + 6000000),
      });
      if (res === true) {
        removeCookie("token");
        logout();
      }
      res === false ? navigate("/user") : navigate("/");
    },
  });

  const HandleAmerican = async (formData) => {
    const isAmericanCitizen = formData.american_citizen === "true";

    const isSubscribed = isAmericanCitizen
      ? formData.subscribed === "true"
      : true;

    if (isAmericanCitizen) {
      toast.success(
        "Thanks for your interest in Carré! You're officially on our waiting list. Exciting things are in the works for our new offering to U.S. customers. Stay tuned – we'll notify you as soon as it's ready. Your anticipation means a lot to us!"
      );
    }

    await mutation.mutate({
      subscribed: isSubscribed,
      american_citizen: isAmericanCitizen,
    });
  };

  const isAmericanCitizen = watch("american_citizen") === "true";

  const setStepBasedOnGuideStep = () => {
    const guideStep = data?.guide_step ? data?.guide_step.toUpperCase() : "";

    switch (guideStep) {
      case "":
        setCurrentStep(1);
        break;
      case "AMERICAN_CITIZEN":
        setCurrentStep(1);
        break;

      case "APPLICATION":
        setCurrentStep(2);
        break;
      case "ADD NEW ADDRESS":
        setCurrentStep(3);
        break;
      case "KYC":
        setCurrentStep(4);
        break;
      case "DEPOSIT":
        setCurrentStep(5);
        break;
      case "BUY":
        setCurrentStep(6);
        break;
      case "FINISH":
        // Handle 'FINISH' step if needed
        break;
      default:
        break;
    }
  };

  const { mutate: updateStep } = useMutation(useUpdateSteps, {
    onSettled: (data, error) => {
      if (error) {
        notifyError(`Error updating step "${data}"`);
      } else {
      }
    },
  });

  const handleStepChange = (newStep) => {
    let stepName = "";

    // Determine the step name based on the new step
    switch (newStep) {
      case 1:
        stepName = "APPLICATION";
        break;
      case 2:
        stepName = "ADDRESS";
        break;
      case 3:
        stepName = "KYC";
        break;
      // case 4:
      //   stepName = "DEPOSIT";
      //   break;
      // case 5:
      //   stepName = "BUY";
      //   break;
      // case 6:
      //   stepName = "FINISH";
      default:
        break;
    }
    updateStep(stepName);
  };

  useEffect(() => {
    setStepBasedOnGuideStep();
    if (data) {
      if (
        (data?.kyc_verify === false || onBoarding === "true") &&
        data?.guide_step !== "FINISH"
      ) {
        setShowOnboarding(true);
      }
    }
    if (data?.guide_step === "LATER_KYC") {
      setShowOnboarding(false);
    }
  }, [data, onBoarding]);
  useEffect(() => {
    setStepBasedOnGuideStep();
  }, [showOnboarding]);
  const handleClose = () => {
    setVerificationId(undefined);
    setShowVerificationWidget(false);
    setDataSpikeVerificationId(undefined);
    setDataSpikeErrorMessage(undefined);
    setKycFailedMessage(undefined);
    setShowOnboarding(false);
    localStorage.setItem("onboarding", false);
    setCurrentStep(setStepBasedOnGuideStep);
  };

  const nextStep = () => {
    if (currentStep < 5) {
      setCurrentStep(currentStep + 1);
    }
    // handleStepChange(currentStep + 1);
  };

  useEffect(() => {
    if (data?.guide_step !== "KYC") {
      setShowVerificationWidget(false);
    }
  }, [data]);
  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const notifyError = () => {
    if (currentStep !== 1) {
      toast.error(errors?.response?.data?.error || "Something went wrong");
    } else {
    }
  };

  const { mutate } = useMutation(useProfileDataKyc, {
    onSuccess: (data) => {
      refetch();
      setVerificationId(data.verification_url_id);
      setDataSpikeVerificationId(data.id);
      setKycFailedMessage(undefined);
      setDataSpikeErrorMessage(undefined);
      setShowVerificationWidget(true);
    },
    onError: () => {
      notifyError();
    },
  });

  const {
    mutate: handleAmericanCitizen,
    isError,
    error,
  } = useMutation(apiAmericanCitizen, {
    onSuccess: (res) => {
      setCookies("american_citizen", res, {
        expires: new Date(Date.now() + 6000000),
      });
      if (res === true) {
        removeCookie("token");
        logout();
      }
      res === false ? navigate("/user") : navigate("/");
    },
  });

  const onSubmit = (data) => {
    setValue("applicant_type", "Person");
    if (currentStep === 1) {
      // HandleAmerican(data);

      const isAmericanCitizen = data.american_citizen === "true";

      const isSubscribed = isAmericanCitizen
        ? data.subscribed === "true"
        : true;

      if (isAmericanCitizen) {
        toast.success(
          "Thanks for your interest in Carré! You're officially on our waiting list. Exciting things are in the works for our new offering to U.S. customers. Stay tuned – we'll notify you as soon as it's ready. Your anticipation means a lot to us!"
        );
      }

      handleAmericanCitizen({
        subscribed: isSubscribed,
        american_citizen: isAmericanCitizen,
      });
      updateStep("APPLICATION");
    }

    if (currentStep === 3) {
      delete data.american_citizen;

      mutate(data);
    }
    nextStep();
  };
  useEffect(() => {
    if (verificationId) {
      setShowVerificationWidget(true);
    }
    if (showVerificationWidget === true) {
      renderWidget({
        verificationId: verificationId,
        setShow: setShowOnboarding,
        handleStepChange: handleStepChange,
      });
    }
  }, [verificationId, data, showVerificationWidget, showOnboarding]);

  useEffect(() => {
    let stopParam = false;
    if (currentStep === 4 && verificationId !== undefined && !stopParam) {
      const intervalId = setInterval(() => {
        refetchDataSpikeStatus();
      }, 2500);

      return () => clearInterval(intervalId);
    }
  }, [currentStep, dataSpikeVerificationId]);

  return (
    <Modal
      show={showOnboarding}
      onHide={handleClose}
      centered
      size={"xl"}
      style={{ zIndex: 1100 }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {/*<Modal.Header closeButton>*/}
        {/*  <Modal.Title>Complete your KYC</Modal.Title>*/}

        {/*  <p className="fs-lg fw-medium">*/}
        {/*    {currentStep === 1*/}
        {/*      ? "Are you an american citizen"*/}
        {/*      : currentStep === 2*/}
        {/*      ? "Enter your information"*/}
        {/*      : currentStep === 3*/}
        {/*      ? "Enter your address"*/}
        {/*      : currentStep === 4*/}
        {/*      ? "Verify your identify"*/}
        {/*      : currentStep === 5*/}
        {/*      ? "Deposit money into your account"*/}
        {/*      : currentStep === 6*/}
        {/*      ? "Buy your first share"*/}
        {/*      : currentStep === 7*/}
        {/*      ? "Finish"*/}
        {/*      : "Personal Info"}*/}
        {/*  </p>*/}
        {/*</Modal.Header>*/}
        <Modal.Body style={{ maxHeight: "90vh", overflowY: "auto" }}>
          <div>
            <Button
              variant="link"
              className="position-absolute top-10 end-0"
              // style={{ zIndex: 1 }}
              onClick={() => {
                setVerificationId(undefined);
                setDataSpikeVerificationId(undefined);
                setDataSpikeErrorMessage(undefined);
                setKycFailedMessage(undefined);
                setShowVerificationWidget(false);
                setShowOnboarding(false);
              }}
            >
              <IoMdClose size="25" />
            </Button>
            <p className="fw-medium text-black fs-3 mt-1">
              Complete your profile
            </p>
            <p>
              {" "}
              {currentStep === 1
                ? "Verify your identity to continue with your account setup."
                : currentStep === 2
                ? "Verify your identity to continue with your account setup."
                : currentStep === 3
                ? "Verify your identity to continue with your account setup."
                : currentStep === 4
                ? "Verify your identity to continue with your account setup."
                : currentStep === 5
                ? "Secure your investment by depositing funds into your account, enabling you to start owning shares in properties."
                : currentStep === 6
                ? "Buy your first share"
                : currentStep === 7
                ? "Finish"
                : "Personal Info"}
            </p>
          </div>
          <div className="mb-4">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center text-black gap-2">
                <MdInfoOutline size="25" />
                <p className="fw-normal text-black fs-4">
                  {" "}
                  {currentStep === 1
                    ? "U.S. Users Eligibility Notice"
                    : currentStep === 2
                    ? "Fill your information"
                    : currentStep === 3
                    ? "Fill your address"
                    : currentStep === 4
                    ? "Verify your identify"
                    : currentStep === 5
                    ? "Deposit money"
                    : currentStep === 6
                    ? "Buy your first share"
                    : currentStep === 7
                    ? "Finish"
                    : "Personal Info"}
                </p>
              </div>
              <div className="text-muted">Step {currentStep} of 6</div>
            </div>
            {kycFailedMessage && (
              <>
                <p>{kycFailedMessage}</p>
                <p className="text-carre-red">{dataSpikeErrorMessage}</p>
              </>
            )}
            {currentStep === 1 && (
              <p className="text-muted">
                Due to regulatory restrictions, Carré.ai cannot offer services
                to U.S. residents or citizens. This ensures compliance with
                international investment laws and protects our global community.
              </p>
            )}
          </div>
          {/*<Steps className="mb-5">*/}
          {/*  <Step active={currentStep >= 1}>American Citizen</Step>*/}

          {/*  <Step active={currentStep >= 2}>Personal info</Step>*/}
          {/*  <Step active={currentStep >= 3}>Address</Step>*/}
          {/*  <Step active={currentStep >= 4}>Kyc verification</Step>*/}
          {/*  <Step active={currentStep >= 5}>Deposit</Step>*/}
          {/*  <Step active={currentStep >= 6}>Buy share</Step>*/}
          {/*  /!*<Step active={currentStep >= 6}>Finish</Step>*!/*/}
          {/*</Steps>*/}
          {currentStep === 1 && (
            <div>
              <Form>
                <Form.Group>
                  <Form.Label style={{ textAlign: "center" }}>
                    Are you a resident or citizen of the United States or its
                    dependent territories?
                  </Form.Label>
                  <Controller
                    defaultValue="false"
                    name="american_citizen"
                    control={control}
                    render={({ field }) => (
                      <Form.Control size="sm" as="select" {...field} required>
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                      </Form.Control>
                    )}
                  />
                </Form.Group>

                {isAmericanCitizen && (
                  <Form.Group>
                    <Form.Label
                      style={{ textAlign: "center" }}
                      className="fs-sm my-4"
                    >
                      Unfortunately, Carré is currently not available for the
                      United States. If you're interested in being informed when
                      Carré becomes available, please let us know below.
                    </Form.Label>
                    <Controller
                      defaultValue="true"
                      name="subscribed"
                      control={control}
                      render={({ field }) => (
                        <Form.Control size="sm" as="select" {...field} required>
                          <option value="" disabled>
                            Select an option
                          </option>
                          <option value="true">
                            Yes, please add me to the list
                          </option>
                          <option value="false">No thank you</option>
                        </Form.Control>
                      )}
                    />
                  </Form.Group>
                )}
              </Form>
            </div>
          )}
          {currentStep === 2 && (
            <div className="mt-2">
              <div className="d-flex justify-content-between gap-2">
                <Form.Group className="w-50">
                  <Form.Label className="fs-sm fw-medium text-dark">
                    First Name
                  </Form.Label>
                  <Controller
                    name="info.first_name"
                    control={control}
                    defaultValue={data?.first_name}
                    render={({ field }) => (
                      <Form.Control
                        size="sm"
                        type="text"
                        placeholder="Enter first name"
                        {...field}
                        required
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group className="w-50">
                  <Form.Label className="fs-sm fw-medium text-dark">
                    Last Name
                  </Form.Label>
                  <Controller
                    name="info.last_name"
                    control={control}
                    defaultValue={data?.last_name}
                    render={({ field }) => (
                      <Form.Control
                        size="sm"
                        type="text"
                        placeholder="Enter last name"
                        {...field}
                        required
                      />
                    )}
                  />
                </Form.Group>
              </div>
              <Form.Group>
                <Form.Label className="fs-sm fw-medium text-dark">
                  Date of Birth
                </Form.Label>
                <Controller
                  name="info.dob"
                  type="string"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Control
                      size="sm"
                      format="yyyy-MM-dd"
                      type="date"
                      defaultValue="Enter date of birth"
                      {...field}
                      required
                    />
                  )}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="fs-sm fw-medium text-dark">
                  Gender
                </Form.Label>
                <Controller
                  name="info.gender"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Control as="select" {...field} required size="sm">
                      <option disabled value="">
                        Select gender
                      </option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </Form.Control>
                  )}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="fs-sm fw-medium text-dark">
                  Phone number
                </Form.Label>
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <PhoneInput
                      inputClass="w-100 "
                      regions={[
                        "america",
                        "europe",
                        "asia",
                        "oceania",
                        "africa",
                      ]}
                      value={field.value} // Set the value to the form field's value
                      onChange={(phone) => field.onChange(phone)} // Update the form field value when the PhoneInput value changes
                    />
                  )}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="fs-sm fw-medium text-dark">
                  Country
                </Form.Label>
                <Controller
                  name="info.country"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Control size="sm" as="select" {...field} required>
                      <option value="" disabled>
                        Select a country
                      </option>
                      {countryList.map((country) => (
                        <option key={country.Code} value={country.Code}>
                          {country.Name}
                        </option>
                      ))}
                    </Form.Control>
                  )}
                />
              </Form.Group>
            </div>
          )}

          {currentStep === 3 && (
            <div>
              <div className="d-flex justify-content-between gap-2">
                <Form.Group className="w-50">
                  <Form.Label className="fs-sm fw-medium text-dark">
                    Address
                  </Form.Label>
                  <Controller
                    name="info.address"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Form.Control
                        size="sm"
                        type="text"
                        placeholder="Enter address"
                        {...field}
                        required
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group className="w-50">
                  <Form.Label className="fs-sm fw-medium text-dark">
                    Postal Code
                  </Form.Label>
                  <Controller
                    name="info.addresses.residence.postal_code"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Form.Control
                        size="sm"
                        type="text"
                        placeholder="Enter postal code"
                        {...field}
                        required
                      />
                    )}
                  />
                </Form.Group>
              </div>
              <div className="d-flex justify-content-between gap-2">
                <Form.Group className="w-50">
                  <Form.Label className="fs-sm fw-medium text-dark">
                    Apartment, suite, etc*
                  </Form.Label>
                  <Controller
                    name="info.addresses.residence.street"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Form.Control
                        size="sm"
                        type="text"
                        placeholder="Enter apartment, suite nr"
                        {...field}
                        // required
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group className="w-50">
                  <Form.Label className="fs-sm fw-medium text-dark">
                    City
                  </Form.Label>
                  <Controller
                    name="info.addresses.residence.city"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Form.Control
                        size="sm"
                        type="text"
                        placeholder="Enter city"
                        {...field}
                        required
                      />
                    )}
                  />
                </Form.Group>
              </div>
              <Form.Group>
                <Form.Label className="fs-sm fw-medium text-dark">
                  Country
                </Form.Label>
                <Controller
                  name="info.addresses.residence.country"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Control size="sm" as="select" {...field} required>
                      <option value="" disabled>
                        Select a country
                      </option>
                      {countryList.map((country) => (
                        <option key={country.Code} value={country.Code}>
                          {country.Name}
                        </option>
                      ))}
                    </Form.Control>
                  )}
                />
              </Form.Group>
            </div>
          )}

          {currentStep === 5 && (
            <div className="mt-5">
              <PaymentMethod
                isOnBoarding={true}
                setOnBoarding={setShowOnboarding}
              />
            </div>
          )}
          {data?.guide_step === "KYC" && (
            <>
              {!verificationId && (
                <Button
                  className="w-100"
                  onClick={() =>
                    mutate({
                      applicant_type: "",
                      phone: "",
                      info: {
                        address: "",
                        addresses: {
                          residence: {
                            postal_code: "",
                            street: "",
                            city: "",
                            country: "",
                          },
                        },
                        first_name: "",
                        last_name: "",
                        dob: "",
                        gender: "",
                        country: "",
                      },
                    })
                  }
                >
                  Verify my identity
                </Button>
              )}
              {verificationId !== undefined && (
                <div
                  style={{ width: "100px" }}
                  id="test1"
                  className="w-100 h-100 bg-white overflow-a"
                ></div>
              )}
            </>
          )}

          {currentStep === 6 && (
            <div className="d-flex flex-column justify-content-center align-items-center w-100 my-5 text-start">
              <p className="fs-lg fw-normal mb-4 ">
                Follow instructions from the video below to buy your first share
              </p>
              <iframe
                width="315"
                height="500"
                src="https://www.youtube.com/embed/asEKbiJuFlU?autoplay=1"
                title="How to buy shares"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <Button
                className="mt-3 w-100 fs-lg fw-medium"
                onClick={() => {
                  setShowOnboarding(false);
                  nav(`/${roleUrl}/marketplace`);
                }}
                // to={``}
              >
                Go To Marketplace
              </Button>{" "}
            </div>
          )}
          {currentStep < 4 && (
            <div className="d-flex justify-content-between gap-2 mt-4">
              {currentStep > 2 && (
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={prevStep}
                  className="w-50 fw-medium"
                >
                  Previous
                </Button>
              )}

              {currentStep < 4 && (
                <Button
                  type="submit"
                  variant="primary"
                  size="sm"
                  className="w-100 fw-medium"
                >
                  Next
                </Button>
              )}
            </div>
          )}
        </Modal.Body>
      </form>
      {/* <PaymentMethodModal /> */}
    </Modal>
  );
};
export default OnboardingModal;
