import { Alert, Button, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import moment from "moment";
import { FaInfoCircle } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GiCash, GiPalm, GiPalmTree, GiReceiveMoney } from "react-icons/gi";
import { TbBeach, TbSettingsDollar } from "react-icons/tb";
import {
  BsFillHouseDownFill,
  BsFillHouseGearFill,
  BsFillHouseUpFill,
} from "react-icons/bs";
import Info from "./Tooltip";
import { fetchVacationStats } from "../hooks/useGetWalletStats";

const VacationOverview = () => {
  const { register, handleSubmit, setValue } = useForm();
  const [vacationStats, setVacationStats] = useState(null);

  useEffect(() => {
    const today = moment().format("YYYY-MM-DD");
    const oneWeekAgo = moment().subtract(1, "week").format("YYYY-MM-DD");
    setValue("dateFrom", oneWeekAgo);
    setValue("dateTo", today);

    const fetchData = async () => {
      try {
        const data = await fetchVacationStats(oneWeekAgo, today); // Updated function call
        setVacationStats(data);
      } catch (error) {
        toast.error("Error fetching vacation stats:", error);
      }
    };
    fetchData();
  }, [setValue]);

  const onSubmit = async (formData) => {
    const { dateFrom, dateTo } = formData;
    try {
      const data = await fetchVacationStats(dateFrom, dateTo); // Updated function call
      setVacationStats(data);
    } catch (error) {
      console.error("Error fetching vacation stats:", error);
    }
  };

  const getIcon = (transactionType) => {
    switch (transactionType) {
      case "SELL_VACATION_POINT":
        return <TbBeach style={{ color: "#e8705b" }} />;
      case "BUY_VACATION_POINT":
        return <TbBeach style={{ color: "#71a171" }} />;
      case "DIVIDEND_PAYMENT":
        return <GiCash style={{ color: "rgba(44,138,197,0.76)" }} />;
      case "RESERVATION":
        return <GiPalmTree style={{ color: "#f39c12" }} />;
      default:
        return null;
    }
  };

  return (
    <section className="py-2 py-md-4">
      <h5 className="card-title widget-card-title mb-3">
        Transactions Details
      </h5>
      <div className="my-2">
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-wrap justify-center"
        >
          <Row>
            <Col md={5}>
              <Form.Group
                size="sm"
                controlId="dateFrom"
                className="w-full sm:w-auto sm:mr-2 mb-2"
              >
                <Form.Label className="mr-2">From Date</Form.Label>
                <Form.Control size="sm" type="date" {...register("dateFrom")} />
              </Form.Group>
            </Col>
            <Col md={5}>
              <Form.Group
                size="sm"
                controlId="dateTo"
                className="w-full sm:w-auto sm:mr-2 mb-2"
              >
                <Form.Label className="mr-2">To Date</Form.Label>
                <Form.Control size="sm" type="date" {...register("dateTo")} />
              </Form.Group>
            </Col>
            <Col
              md={2}
              className="d-flex justify-content-center align-items-center"
            >
              <Button
                size="sm"
                variant="primary"
                type="submit"
                className="w-100"
                style={{ marginTop: "22px" }}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </div>

      <div>
        {vacationStats?.details.length === 0 && (
          <Alert className="text-center fs-sm p-2 fw-semibold">
            No data available for the selected date range.
          </Alert>
        )}
        {vacationStats?.details?.map((transfer, index) => (
          <div key={index} className="row gy-4 mt-1">
            <div className="col-12">
              <div className="row align-items-center fs-sm">
                <div className="col-8">
                  <div className="d-flex align-items-center">
                    <div style={{ flexShrink: 0 }}>
                      <div className="fs-5 bg-carre-gray text-primary rounded-2 align-items-center justify-content-center me-3">
                        <div className="p-2">{getIcon(transfer.type)}</div>
                      </div>
                    </div>
                    <div>
                      <p className="fs-md card-title fw-semibold text-dark text-start">
                        {transfer.detail}{" "}
                        <span className="text-muted">
                          {transfer.property_name}{" "}
                          {transfer.order_token_qty !== 0 &&
                            transfer.order_token_qty}
                        </span>
                      </p>
                      <p className="text-gray m-0 fs-sm">
                        {moment(transfer.created_at).format("l")}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-4 text-end">
                  <div>
                    <p className="fs-md card-title fw-semibold text-dark">
                      {transfer.points?.toLocaleString()}
                    </p>
                    <p
                      className={`text-gray m-0 fs-sm ${
                        transfer.points < 0
                          ? "text-carre-red"
                          : "text-carre-green"
                      }`}
                    >
                      {transfer.total_balance?.toLocaleString()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default VacationOverview;
