import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetPropertyById } from "../hooks/useGetPropertyById";
import {
  Button,
  Card,
  Col,
  Modal,
  Nav,
  Offcanvas,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import { checkPlural } from "../utils/helper";
import CarreToken from "../assets/icons/CarreToken";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import LightBox from "./LightBox";
import { GetRoleNavigationPath } from "../routeUtils";
import { FaSwimmingPool } from "react-icons/fa";
import { CgGames, CgGym } from "react-icons/cg";
import { GrFan } from "react-icons/gr";
import {
  MdLocalLaundryService,
  MdOutdoorGrill,
  MdOutlineDesktopMac,
  MdOutlineSecurity,
  MdSportsVolleyball,
} from "react-icons/md";
import { FiArchive } from "react-icons/fi";
import { AiOutlineWifi } from "react-icons/ai";
import Navbar from "react-bootstrap/Navbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useGetPropertyForReservation } from "../hooks/useGetWalletStats";
import {
  useBookReservation,
  useGetRateCalendar,
} from "../hooks/useRateCalendar";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import GoBackButton from "./GoBackButton";
import { useGetVacationDetails } from "../hooks/useGetVacationDetails";

const BookDetails = () => {
  const { id } = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const startDateQuery = queryParams.get("start_date");
  const endDateQuery = queryParams.get("end_date");

  const [startDate, setStartDate] = useState(
    startDateQuery ? new Date(startDateQuery) : null
  );
  const [endDate, setEndDate] = useState(
    endDateQuery ? new Date(endDateQuery) : null
  );
  const [dateRange, setDateRange] = useState([startDate, endDate]);

  const { data, refetch } = useGetPropertyById(id);
  const roleUrl = GetRoleNavigationPath();
  const navigate = useNavigate();
  const [showAllAmenities, setShowAllAmenities] = useState(false);

  const amenityIcons = {
    swimming_pool: {
      icon: <FaSwimmingPool size={25} />,
      text: "Swimming Pool",
    },
    gym: {
      icon: <CgGym size={25} />,
      text: "Gym",
    },
    air_condition: {
      icon: <GrFan size={25} />,
      text: "AC",
    },
    security: {
      icon: <MdOutlineSecurity size={25} />,
      text: "Security",
    },
    laundry_facilities: {
      icon: <MdLocalLaundryService size={25} />,
      text: "Laundry",
    },
    games_room: {
      icon: <CgGames size={25} />,
      text: "Games Room",
    },
    storage: {
      icon: <FiArchive size={25} />,
      text: "Storage",
    },
    barbecue_area: {
      icon: <MdOutdoorGrill size={25} />,
      text: "Barbecue Area",
    },
    tech_workspace: {
      icon: <MdOutlineDesktopMac size={25} />,
      text: "Tech Workspace",
    },
    free_wifi: {
      icon: <AiOutlineWifi size={25} />,
      text: "Free Wifi",
    },
    sand_volleyball: {
      icon: <MdSportsVolleyball size={25} />,
      text: "Sand Volleyball",
    },
  };
  let allFalse =
    data?.amenities &&
    Object.values(data?.amenities)?.every((value) => value === false);
  const maxAmenitiesToShow = 5;

  // Filter amenities based on whether they are available
  const filteredAmenities =
    data && data.amenities
      ? Object.entries(data.amenities).filter(
          ([amenityName, isAvailable]) => isAvailable
        )
      : [];
  // Display only a subset of amenities based on showAllAmenities state
  const displayedAmenities = showAllAmenities
    ? filteredAmenities
    : filteredAmenities.slice(0, maxAmenitiesToShow);

  const toggleShowAllAmenities = () => {
    setShowAllAmenities(!showAllAmenities);
  };

  const addTwoHoursAndFormat = (date) => {
    if (!date) return null;
    // Add 2 hours to the date
    const newDate = new Date(date.getTime() + 2 * 60 * 60 * 1000);
    // Return formatted string YYYY-MM-DD
    return newDate.toISOString().split("T")[0];
  };

  const { data: vacationData } = useGetVacationDetails();

  const formattedStartDate = addTwoHoursAndFormat(startDate);
  const formattedEndDate = addTwoHoursAndFormat(endDate);
  const {
    data: calendar,
    isLoading,
    error,
  } = useGetPropertyForReservation(formattedStartDate, formattedEndDate, id);
  const numberOfStays = calendar?.[0]?.total_amount / calendar?.[0]?.price_avg;
  const totalPrice = calendar?.[0]?.price_avg * numberOfStays;
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setDateRange([start, end]);
    if (start && end) {
      setOpen(false);
      setOpenMobileCalendar(false);
    }
  };

  const [open, setOpen] = useState(false);

  const datepickerRef = useRef(null);
  const toggleCalendar = () => {
    setOpen(!open);
  };

  const closeCalendar = (e) => {
    if (popoverRef.current && !popoverRef.current.contains(e.target)) {
      setOpen(false);
    }
  };
  React.useEffect(() => {
    document.addEventListener("mousedown", closeCalendar);
    return () => {
      document.removeEventListener("mousedown", closeCalendar);
    };
  }, []);

  const [openMobileCalendar, setOpenMobileCalendar] = useState(false);

  const toggleMobileCalendar = () => {
    setOpen(!open);
  };

  const closeMobileCalendar = (e) => {
    if (popoverRef.current && !popoverRef.current.contains(e.target)) {
      setOpen(false);
    }
  };
  React.useEffect(() => {
    document.addEventListener("mousedown", closeMobileCalendar);
    return () => {
      document.removeEventListener("mousedown", closeMobileCalendar);
    };
  }, []);

  const popoverStyles = {
    width: "auto", // Adjust width as needed
    maxWidth: "none", // Remove default max-width
    zIndex: 1050, // Ensure the popover appears above other content
  };
  const popoverRef = useRef(null);

  const { data: rateCalendar, refetch: refetchRateCalendar } =
    useGetRateCalendar(id);
  const [availableDates, setAvailableDates] = useState([]);
  useEffect(() => {
    if (rateCalendar) {
      const dates = rateCalendar.map((item) => new Date(item.date));
      setAvailableDates(dates);
    }
  }, [rateCalendar]);
  const popover = (
    <Popover id="date-picker-popover" style={popoverStyles} ref={popoverRef}>
      <Popover.Body>
        <DatePicker
          selected={startDate}
          onChange={handleDateChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
          monthsShown={window.innerWidth <= 768 ? 1 : 2}
          dateFormat="MMM d"
          includeDates={availableDates}
        />
      </Popover.Body>
    </Popover>
  );

  const popoverMobile = (
    <Popover id="date-picker-popover-mobile" ref={popoverRef}>
      <Popover.Body>
        <DatePicker
          selected={startDate}
          onChange={handleDateChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          monthsShown={1}
          dateFormat="MMM d"
          includeDates={availableDates}
        />
      </Popover.Body>
    </Popover>
  );
  const isCalendarEmpty = calendar?.length === 0;
  const { mutate } = useBookReservation();
  const queryClient = useQueryClient();

  const handleBooking = () => {
    if (startDate && endDate) {
      const reservationData = {
        property_id: id,
        date_from: startDate.toISOString(),
        date_to: endDate.toISOString(),
      };
      mutate(reservationData, {
        onSuccess: () => {
          toast.success("Booked successfully");
          refetchRateCalendar();
          navigate("/user/vacation/reservation");
          queryClient.invalidateQueries(["reservation-request-for-user"]);
          queryClient.invalidateQueries(["vacation"]);

          // userRefetch(); // Refetch user or other data as needed
        },
        onError: () => {
          toast.error("Booking failed");
          // Handle error accordingly
        },
      });
    }
  };
  if (!data) return <div>Loading...</div>;

  return (
    <div style={{ marginBottom: "200px" }}>
      <div className="container mb-2">
        <GoBackButton />

        <Row>
          <Col sm={10}>
            <div className="mt-2 h-100 ">
              <p className="text-carre-black fs-md fw-bold">
                {data?.property_name}
              </p>
              <div
                style={{ display: "flex", flexWrap: "wrap", gap: "7px" }}
                className="fs-md"
              >
                {data?.bedroom_count > 0 && (
                  <span>
                    {checkPlural(data?.bedroom_count, "Bedroom", "Bedrooms")}
                  </span>
                )}
                {data?.bathrooms > 0 && (
                  <span>
                    {checkPlural(data?.bathrooms, "Bathroom", "Bathrooms")}
                  </span>
                )}

                {data?.parking > 0 && (
                  <span>
                    {checkPlural(data?.parking, "Parking", "Parkings")}
                  </span>
                )}
                {data?.property_area > 0 && (
                  <span>{data?.property_area} m²</span>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="d-block d-lg-none mb-3 mx-3">
        {data?.property_owned_token !== null && (
          <div
            className="position-absolute text-center"
            style={{ left: "0px", top: "0", padding: "5px" }}
          >
            <p
              className="text-uppercase fs-md text-carre-blue  bg-carre-gray p-2 rounded-pill mt-1"
              style={{ fontSize: "14px" }}
            >
              <span className="mt-2">
                {data?.property_owned_token?.token_qty}
              </span>{" "}
              <CarreToken />
            </p>
          </div>
        )}
        <Splide
          className=""
          options={{
            perPage: 1,
            height: "25rem",
            rewind: true,
            gap: "1rem",
          }}
          aria-labelledby="basic-example-heading"
        >
          {data?.image?.map((slide) => (
            <SplideSlide key={slide.image_url}>
              <img
                src={slide.image_url}
                alt="Default"
                style={{
                  objectFit: "cover",
                }}
                className="w-100 h-100 rounded-2"
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>
      <div className="d-none d-lg-block container">
        <LightBox data={data} />
      </div>
      <div className="container d-flex gap-4 align-items-center ">
        <span className="text-muted">
          <span>{data?.address.street_name}</span>
          {data?.description.street_name} {data?.address.city_name}
          ,&nbsp;
          {data?.address.country_state} {data?.address.country_name}
          &nbsp;
          {data?.address.zipcode}
        </span>
      </div>
      <div className="container mb-4 ">
        <Row>
          <Col>
            {allFalse === false && (
              <div className="flex flex-wrap gap-5">
                <h5 className="my-2">Amenities:</h5>
                <Row
                  as="ul"
                  sm={2}
                  xs={1}
                  md={2}
                  lg={2}
                  className="list-unstyled gy-1 gx-5 mb-1 text-nowrap"
                >
                  {displayedAmenities.map(([amenityName]) => (
                    <Col as="li" className=" " key={amenityName}>
                      <div className="d-inline-block text-black mx-2">
                        {amenityIcons[amenityName]?.icon}&nbsp;&nbsp;
                      </div>
                      <div className="fs-md align-middle d-inline-block">
                        {amenityIcons[amenityName]?.text}
                      </div>
                    </Col>
                  ))}
                </Row>
                {filteredAmenities.length >= 6 && (
                  <div className="ms-auto mx-5">
                    <a
                      onClick={toggleShowAllAmenities}
                      aria-controls="moreAbout"
                      aria-expanded={showAllAmenities}
                      className={`collapse-label${
                        showAllAmenities ? "" : " collapsed"
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      {showAllAmenities ? "Show less" : "Show more"}
                    </a>
                  </div>
                )}
              </div>
            )}
            <div className="my-4 ">
              <h3>Overview</h3>
              <p
                style={{ maxHeight: "12rem", overflowY: "auto" }}
                className="text-muted collapse show"
                id="collapseSummary"
                dangerouslySetInnerHTML={{ __html: data?.description }}
              ></p>
            </div>
          </Col>

          <Col className="p-3 overflow-visible h-100 d-none d-md-block">
            <div className="d-flex flex-column align-items-end justify-content-end h-100">
              <Card className="w-full max-w-md mx-4">
                {isCalendarEmpty ? (
                  <Card.Header>
                    <p className="text-danger">
                      The property is not available for these days.
                    </p>
                  </Card.Header>
                ) : (
                  <Card.Header>
                    <p
                      className="text-decoration-underline text-medium fw-medium"
                      style={{ cursor: "pointer" }}
                      onClick={handleShow}
                    >
                      <strong>{totalPrice.toLocaleString()}</strong> vacation
                      point total
                    </p>
                    <p className=" text-medium fw-medium">
                      <strong>{vacationData?.balance.toLocaleString()}</strong>{" "}
                      vacation point available
                    </p>
                    {!isCalendarEmpty && vacationData?.balance < totalPrice && (
                      <div className="w-100 text-center mb-2">
                        <p className="text-danger w-100">
                          You don't have enough VP
                        </p>
                        <button
                          onClick={() => navigate("/user/vacation/order")}
                          className="btn btn-primary w-100  fs-md fw-semibold"
                        >
                          <i className="bi bi-cart-fill"></i> Go to Order Page
                        </button>
                      </div>
                    )}
                  </Card.Header>
                )}

                <Card.Body>
                  <form>
                    <div className="d-grid gap-4">
                      <div className="d-flex gap-2">
                        <div ref={datepickerRef} className="w-100">
                          <OverlayTrigger
                            className="w-100"
                            trigger="click"
                            placement="auto"
                            overlay={popover}
                            show={open}
                            onToggle={toggleCalendar}
                          >
                            <Button
                              variant="outline-secondary"
                              className="d-flex justify-content-between align-items-center w-100 gap-5"
                            >
                              <div className="d-flex flex-column align-items-start">
                                <span className="fw-semibold fs-md">
                                  Check in
                                </span>
                                <span className="fs-md fw-normal">
                                  {startDate
                                    ? startDate.toLocaleDateString("en-GB")
                                    : "Select Date"}
                                </span>
                              </div>
                              <div className="d-flex flex-column align-items-end">
                                <span className="fw-semibold fs-md">
                                  Check out
                                </span>
                                <span className="fs-md fw-normal">
                                  {" "}
                                  {endDate
                                    ? endDate.toLocaleDateString("en-GB")
                                    : "Select Date"}
                                </span>
                              </div>
                            </Button>
                          </OverlayTrigger>
                        </div>
                        {/*<OverlayTrigger*/}
                        {/*    trigger="click"*/}
                        {/*    placement="bottom"*/}
                        {/*    overlay={renderPopover('CheckIn')}*/}
                        {/*>*/}
                        {/*  <Button variant="outline-secondary" className="d-flex flex-column align-items-start w-100">*/}
                        {/*    <span className="font-weight-bold text-uppercase" style={{ fontSize: '0.65rem' }}>Check in</span>*/}
                        {/*    <span className="font-weight-normal">Select date</span>*/}
                        {/*  </Button>*/}
                        {/*</OverlayTrigger>*/}
                        {/*<OverlayTrigger*/}
                        {/*    trigger="click"*/}
                        {/*    placement="bottom"*/}
                        {/*    overlay={renderPopover('CheckOut')}*/}
                        {/*>*/}
                        {/*<Button*/}
                        {/*  variant="outline-secondary"*/}
                        {/*  className="d-flex flex-column align-items-start w-100"*/}
                        {/*>*/}
                        {/*  <span*/}
                        {/*    className="font-weight-bold text-uppercase"*/}
                        {/*    style={{ fontSize: "0.65rem" }}*/}
                        {/*  >*/}
                        {/*    Check out*/}
                        {/*  </span>*/}
                        {/*  <span className="font-weight-normal">*/}
                        {/*    Select date*/}
                        {/*  </span>*/}
                        {/*</Button>*/}
                        {/*</OverlayTrigger>*/}
                      </div>
                      <div>
                        <Button
                          size="lg"
                          className="w-100 h-12"
                          disabled={
                            !isCalendarEmpty &&
                            vacationData?.balance < totalPrice
                          }
                          onClick={handleBooking}
                        >
                          Book Now
                        </Button>
                      </div>
                    </div>
                  </form>
                </Card.Body>
              </Card>

              {/*              <div className="text-center h6">*/}
              {/*                <Info*/}
              {/*                  classNames="fs-xs text-left"*/}
              {/*                  placement="top"*/}
              {/*                  list={[*/}
              {/*                    "Rental Income: Earn daily income from rental payments generated by the properties you invest in.\n",*/}
              {/*                    "Appreciation: Benefit from the potential increase in property value over time, allowing your investment to grow.\n",*/}
              {/*                    "Vacation Points: Enjoy the flexibility to use vacation points for complimentary stays at our properties, adding an extra perk to your investment portfolio. Vacation Points can also be sold on the platform",*/}
              {/*                  ]}*/}
              {/*                  text="*/}
              {/*The Expected (Annual) Total Return is the sum of three annual return that Carre offers to his investors. :"*/}
              {/*                >*/}
              {/*                  <div className="text-muted">*/}
              {/*                    <span className=" float-start">Expected Total Return</span>*/}
              {/*                    <span className="mx-1 text-carre-blue float-end">*/}
              {/*                      {(*/}
              {/*                        data?.net_expected_cash_return +*/}
              {/*                        data?.vacation_token_exceptions +*/}
              {/*                        data?.net_appreciation*/}
              {/*                      ).toFixed(2)}{" "}*/}
              {/*                      %*/}
              {/*                    </span>*/}
              {/*                  </div>*/}
              {/*                </Info>*/}
              {/*              </div>*/}
            </div>
          </Col>
        </Row>
      </div>

      <Navbar
        fixed="bottom"
        bg="white"
        variant="white"
        className="d-block d-md-none"
      >
        <div className="d-flex justify-content-center align-items-center ">
          {/*<Nav className="d-block d-md-none align-items-center ">*/}
          {/*  /!*<Button*!/*/}
          {/*  /!*  style={{ width: "130px" }}*!/*/}
          {/*  /!*  size="sm"*!/*/}
          {/*  /!*  variant="carre-blue btn-lg mx-2"*!/*/}
          {/*  /!*  // onClick={() => navigate(`/${roleUrl}/buy/${data?.id}`)}*!/*/}
          {/*  /!*>*!/*/}
          {/*  /!*  Book*!/*/}
          {/*  /!*</Button>*!/*/}
          {/*  <div ref={datepickerRef} className="w-100">*/}
          {/*    <OverlayTrigger*/}
          {/*      className="w-100 mb-5"*/}
          {/*      trigger="click"*/}
          {/*      placement="auto"*/}
          {/*      overlay={popoverMobile}*/}
          {/*      show={openMobileCalendar}*/}
          {/*      onToggle={toggleMobileCalendar}*/}
          {/*    >*/}
          {/*      <Button*/}
          {/*        variant="outline-secondary"*/}
          {/*        className="d-flex justify-content-between align-items-center w-100 gap-5"*/}
          {/*      >*/}
          {/*        <div className="d-flex flex-column align-items-start">*/}
          {/*          <span className="fw-semibold fs-md">Check in</span>*/}
          {/*          <span className="fs-md fw-normal">*/}
          {/*            {startDate*/}
          {/*              ? startDate.toLocaleDateString("en-GB")*/}
          {/*              : "Select Date"}*/}
          {/*          </span>*/}
          {/*        </div>*/}
          {/*        <div className="d-flex flex-column align-items-end">*/}
          {/*          <span className="fw-semibold fs-md">Check out</span>*/}
          {/*          <span className="fs-md fw-normal">*/}
          {/*            {" "}*/}
          {/*            {endDate*/}
          {/*              ? endDate.toLocaleDateString("en-GB")*/}
          {/*              : "Select Date"}*/}
          {/*          </span>*/}
          {/*        </div>*/}
          {/*      </Button>*/}
          {/*    </OverlayTrigger>*/}
          {/*  </div>*/}
          {/*  <Button*/}
          {/*    size="lg"*/}
          {/*    className="w-100 h-12"*/}
          {/*    disabled={isCalendarEmpty}*/}
          {/*    onClick={handleBooking}*/}
          {/*  >*/}
          {/*    Book Now*/}
          {/*  </Button>*/}
          {/*</Nav>*/}
          <Nav className="d-block d-md-none align-items-center w-100 mx-4">
            {/*<Button*/}
            {/*  style={{ width: "130px" }}*/}
            {/*  size="sm"*/}
            {/*  variant="carre-blue btn-lg mx-2"*/}
            {/*  // onClick={() => navigate(`/${roleUrl}/buy/${data?.id}`)}*/}
            {/*>*/}
            {/*  Book*/}
            {/*</Button>*/}
            <p
              className="text-decoration-underline text-medium fw-medium"
              style={{ cursor: "pointer" }}
              onClick={handleShow}
            >
              <strong>{totalPrice.toLocaleString()}</strong> vacation point
              total
            </p>
            <p className=" text-medium fw-medium">
              <strong>{vacationData?.balance.toLocaleString()}</strong> vacation
              point available
            </p>

            {!isCalendarEmpty && vacationData?.balance < totalPrice && (
              <div className="w-100 text-center mb-2">
                <p className="text-danger w-100">You don't have enough VP</p>
                <button
                  onClick={() => navigate("/user/vacation/order")}
                  className="btn btn-primary w-100  fs-md fw-semibold"
                >
                  <i className="bi bi-cart-fill"></i> Go to Order Page
                </button>
              </div>
            )}

            <Button
              variant="outline-secondary"
              className="d-flex justify-content-between align-items-center w-100 gap-5 mb-3"
              onClick={() => setOpenMobileCalendar(true)}
            >
              <div className="d-flex flex-column align-items-start">
                <span className="fw-semibold fs-md">Check in</span>
                <span className="fs-md fw-normal">
                  {startDate
                    ? startDate.toLocaleDateString("en-GB")
                    : "Select Date"}
                </span>
              </div>
              <div className="d-flex flex-column align-items-end">
                <span className="fw-semibold fs-md">Check out</span>
                <span className="fs-md fw-normal">
                  {endDate
                    ? endDate.toLocaleDateString("en-GB")
                    : "Select Date"}
                </span>
              </div>
            </Button>

            <Button
              size="lg"
              className="w-100 h-12"
              disabled={isCalendarEmpty}
              onClick={handleBooking}
            >
              Book Now
            </Button>

            <Offcanvas
              show={openMobileCalendar}
              onHide={() => setOpenMobileCalendar(false)}
              placement="bottom"
              className="h-auto"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Select Dates</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body style={{ top: "10px", right: "10px" }}>
                <DatePicker
                  style={{ width: "100%" }}
                  selected={startDate}
                  onChange={handleDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  monthsShown={1}
                  dateFormat="MMM d"
                  includeDates={availableDates}
                  inline
                />
              </Offcanvas.Body>
            </Offcanvas>
            {isCalendarEmpty && (
              <p className="text-danger">
                The property is not available for these days.
              </p>
            )}
          </Nav>
        </div>
      </Navbar>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="fw-medium fs-lg">Price Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="text-black fs-md">
            <Col xs={6}>
              {calendar?.[0]?.price_avg} VP x {calendar?.[0]?.number_of_stay}{" "}
              night
              {calendar?.[0]?.number_of_stay > 1 && "s"}
            </Col>
            <Col xs={6} className="text-end">
              {totalPrice} VP
            </Col>
          </Row>
          <hr />
          <Row className="text-black fs-md">
            <Col xs={6}>Total</Col>
            <Col xs={6} className="text-end">
              {totalPrice} vacation point
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BookDetails;
