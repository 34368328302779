import React from "react";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import { useMutation } from "react-query";
import api from "../api";
import { useGenerateUser } from "../hooks/useGenerateUser";
import { toast } from "react-toastify";

const GenerateUserPage = () => {
  const generateUserMutation = useMutation(useGenerateUser, {
    onSuccess: (data) => {
      toast.success("User created successfully");
    },
  });

  const { data, isLoading, isError } = generateUserMutation;

  const handleGenerateUser = () => {
    generateUserMutation.mutate();
  };

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <Button onClick={handleGenerateUser} disabled={isLoading}>
            Generate User
          </Button>
          {isError && <div>Error generating user</div>}
        </Col>
      </Row>
      <Row>
        <Col>
          {data && (
            <Form>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control type="text" readOnly value={data.user.email} />
                <Button onClick={() => handleCopy(data.user.email)}>
                  Copy
                </Button>
              </Form.Group>
              <Form.Group>
                <Form.Label>Hashed Password</Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  value={data.user.hashed_password}
                />
                <Button onClick={() => handleCopy(data.user.hashed_password)}>
                  Copy
                </Button>
              </Form.Group>
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  value={data.user.first_name}
                />
                <Button onClick={() => handleCopy(data.user.first_name)}>
                  Copy
                </Button>
              </Form.Group>
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  value={data.user.last_name}
                />
                <Button onClick={() => handleCopy(data.user.last_name)}>
                  Copy
                </Button>
              </Form.Group>
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default GenerateUserPage;
