import { useQuery } from "react-query";
import api from "../api";

export const useGetPropertyForReservation = (dateFrom, dateTo, propertyId) => {
  return useQuery(["reservation/search", dateFrom, dateTo, propertyId], () => {
    const queryParams = { date_from: dateFrom, date_to: dateTo };

    if (propertyId) {
      queryParams.type = "PROPERTY";
      queryParams.value = propertyId;
    }

    return api
      .get("/reservation/search", { params: queryParams })
      .then((res) => res.data);
  });
};

export const fetchWalletStats = async (dateFrom, dateTo) => {
  const queryParams = { date_from: dateFrom, date_to: dateTo };
  const response = await api.get("/wallet-stats", { params: queryParams });
  return response.data;
};
export const fetchVacationStats = async (dateFrom, dateTo) => {
  const queryParams = { date_from: dateFrom, date_to: dateTo };
  const response = await api.get("/vacation/stats", { params: queryParams });
  return response.data;
};
