import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  BsFillDiamondFill,
  BsFillBarChartFill,
  BsFillCollectionFill,
} from "react-icons/bs";
import { MdOutlineAttachMoney, MdOutlineShoppingBag } from "react-icons/md";
import { IoMdTime } from "react-icons/io";
import { IoCheckmark } from "react-icons/io5";
import { BiPieChart } from "react-icons/bi";
import {
  FcBullish,
  FcElectricity,
  FcLandscape,
  FcPieChart,
} from "react-icons/fc";

const WhyUsSection = () => {
  return (
    <section className="container py-5 my-md-2 my-lg-3 my-xl-4 my-xxl-5">
      <div className="row align-items-center py-1 py-sm-3 py-md-4 my-lg-2">
        <div className="col-lg-4 offset-xl-1">
          <h2 className="h1 text-center text-lg-start pb-3 pb-lg-1 fw-semibold">
            How Carré makes it easy to invest
          </h2>
          {/* Show on screens > 992px */}
          <ul className="list-unstyled d-none d-lg-block pb-3 mb-3 mb-lg-4 ">
            <li className="d-flex pt-2">
              <IoCheckmark className="ai-check-alt fs-4 mr-2 me-2 text-success" />
              Start investing with as little as $100.
            </li>
            <li className="d-flex pt-2">
              <IoCheckmark className="ai-check-alt fs-4 mr-2 me-2 text-success" />
              We handle all property management for you.
            </li>
            <li className="d-flex pt-2">
              <IoCheckmark className="ai-check-alt fs-4 mr-2 me-2 text-success" />
              Invest in less than 2 minutes.
            </li>
          </ul>
          {/*<a className="btn btn-primary d-none d-lg-inline-flex" href="#">*/}
          {/*  See all servises*/}
          {/*</a>*/}
        </div>
        <div className="col-lg-8 col-xl-7 col-xxl-6 ">
          <div className="row row-cols-1 row-cols-sm-2">
            <div className="col">
              <div className="card border-0 bg-primary bg-opacity-10">
                <div className="card-body">
                  <FcPieChart size={35} className="mb-2 text-primary" />
                  <h3 className="h4 fw-semibold">
                    Innovative Fractional Ownership
                  </h3>
                  <p className="fs-sm">
                    Discover a revolutionary way to own real estate with Carré's
                    fractional ownership model, making luxury properties
                    accessible, starting at just $100
                  </p>
                </div>
              </div>
              <div className="card border-0 bg-info bg-opacity-10 mt-4">
                <div className="card-body">
                  <FcBullish size={35} className="mb-2 text-primary" />
                  <h3 className="h4 fw-semibold">
                    Profitable Investment Potentials
                  </h3>
                  <p className="fs-sm">
                    We use AI to enhance investments and work with leading real
                    estate developers to lower acquisition costs. We also team
                    up with SuperHost management companies to optimize rental
                    days and pricing.
                  </p>
                </div>
              </div>
            </div>
            <div className="col pt-lg-3">
              <div className="card border-0 bg-warning bg-opacity-10 mt-4 mt-sm-0 mt-lg-4">
                <div className="card-body">
                  <FcElectricity size={35} className="mb-2 text-primary" />
                  <h3 className="h4 fw-semibold">
                    Unmatched Flexibility and Accessibility{" "}
                  </h3>
                  <p className="fs-sm">
                    Invest in your first property in less than 2 minutes and
                    enjoy the convenience of flexible property usage and
                    hassle-free management tailored to your lifestyle needs
                  </p>
                </div>
              </div>
              <div className="card border-0 bg-danger bg-opacity-10 mt-4">
                <div className="card-body">
                  <FcLandscape size={35} className="mb-2 text-primary" />
                  <h3 className="h4 fw-semibold">Exclusive Vacation Points</h3>
                  <p className="fs-sm">
                    Accumulate vacation points with each investment, allowing
                    you to enjoy luxurious stays at properties within the Carré
                    network.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Show button on screens < 992px */}
          {/*<div className="d-lg-none text-center pt-3 mt-3 mt-sm-4">*/}
          {/*  <a className="btn btn-primary" href="#">*/}
          {/*    See all servises*/}
          {/*  </a>*/}
          {/*</div>*/}
        </div>
      </div>
    </section>

    // <section className="why-us-section py-5 container">
    //   <Container className="bg-white  rounded-5 p-5">
    //     <h2>Why Carré</h2>
    //     <Row className="justify-content-center my-4 g-3">
    //       {/* Column 1 */}
    //       <Col lg={4} md={6} className="mb-4 ">
    //         <div>
    //           <MdOutlineAttachMoney
    //             className="icon mb-3 text-carre-blue"
    //             size={40}
    //           />
    //           <h3 className="section-title mb-3">Invest with confidence</h3>
    //           <p className="section-description">
    //             Begin your real estate journey with as little as $100 and unlock
    //             the potential of high-value properties.
    //           </p>
    //         </div>
    //       </Col>
    //       {/* Column 2 */}
    //       <Col lg={4} md={6} className="mb-4 ">
    //         <div>
    //           <IoMdTime className="icon mb-3 text-carre-blue" size={40} />
    //           <h3 className="section-title mb-3">Maximize your returns</h3>
    //           <p className="section-description">
    //             Enjoy passive income and sustainable growth through fractional
    //             ownership of premium real estate.
    //           </p>
    //         </div>
    //       </Col>
    //       {/* Column 3 */}
    //       <Col lg={4} md={6} className="mb-4">
    //         <div>
    //           <MdOutlineShoppingBag
    //             className="icon mb-3 text-carre-blue"
    //             size={40}
    //           />
    //           <h3 className="section-title mb-3">
    //             Expand your investment portfolio
    //           </h3>
    //           <p className="section-description">
    //             Diversify your portfolio by investing in high-value properties
    //             located in prime global markets.
    //           </p>
    //         </div>
    //       </Col>
    //     </Row>
    //   </Container>
    // </section>
  );
};

export default WhyUsSection;
