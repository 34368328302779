import React, { useEffect, useState } from "react";
import { Tabs, Tab, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { FaCalendarAlt, FaMapMarkedAlt, FaExchangeAlt } from "react-icons/fa";
import RangeDatePicker from "./RangeDatePicker";
import BookingTable from "./BookingTable";
import VacationPage from "../pages/Dashboard/Vacation/VacationPage"; // Importing icons

const VacationTabs = () => {
  const [key, setKey] = useState("vacation");
  const { type } = useParams();

  useEffect(() => {
    if (type === "reservation") {
      setKey("reservation");
    } else if (type === "order") {
      setKey("order");
    }
  }, [type]);

  const tabStyle = {
    color: "#6c757d", // Inactive tab text color
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const activeTabStyle = {
    color: "#000", // Active tab text color
    fontWeight: "semibold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const iconStyle = {
    marginRight: "8px",
  };

  return (
    <Container className="mt-md-0 mt-5">
      <Tabs
        id="order-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        variant="tabs"
        className=" bg-carre-gray p-1 gap-3 rounded-2 mt-2"
        fill
      >
        <Tab
          eventKey="vacation"
          title={
            <span style={key === "vacation" ? activeTabStyle : tabStyle}>
              <FaCalendarAlt style={iconStyle} /> Book
            </span>
          }
        >
          <RangeDatePicker />{" "}
        </Tab>

        <Tab
          eventKey="reservation"
          title={
            <span style={key === "reservation" ? activeTabStyle : tabStyle}>
              <FaMapMarkedAlt style={iconStyle} /> My trips
            </span>
          }
        >
          <BookingTable />
        </Tab>

        <Tab
          eventKey="order"
          title={
            <span style={key === "order" ? activeTabStyle : tabStyle}>
              <FaExchangeAlt style={iconStyle} /> Trade
            </span>
          }
        >
          <VacationPage />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default VacationTabs;
