import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import api from "../../api";
import { Table, Modal, Button, Form, Container } from "react-bootstrap";

export const useGetVacationReservationForAdmin = () => {
  return useQuery(["reservation"], () => {
    return api.get("reservation").then((res) => res.data);
  });
};

const updateReservationStatus = async ({ id, status }) => {
  return api.put(`reservation`, { status, id }).then((res) => res.data);
};

const ReservationTable = () => {
  const { data, isLoading, isError, refetch } =
    useGetVacationReservationForAdmin();
  const queryClient = useQueryClient();
  const [statusMap, setStatusMap] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const mutation = useMutation(updateReservationStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(["reservation"]);
      queryClient.invalidateQueries(["vacation"]);

      refetch();
    },
  });

  const handleOpenModal = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedId(null);
  };

  const handleStatusChange = (id, newStatus) => {
    setStatusMap((prevStatusMap) => ({
      ...prevStatusMap,
      [id]: newStatus,
    }));
    handleOpenModal(id);
  };

  const handleConfirmStatusChange = () => {
    if (selectedId) {
      mutation.mutate({ id: selectedId, status: statusMap[selectedId] });
      handleCloseModal();
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading reservations</div>;

  return (
    <Container>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Property Name</th>
            <th>City</th>
            <th>Country</th>
            <th>Total Amount</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((reservation) => (
            <tr key={reservation.id}>
              <td>{reservation.property_name}</td>
              <td>{reservation.city_name}</td>
              <td>{reservation.country_name}</td>
              <td>{reservation.total_amount}</td>
              <td>{reservation.status}</td>
              <td>
                {reservation.status !== "CANCELLED" &&
                reservation.status !== "REJECTED" &&
                reservation.status !== "RESERVED" &&
                reservation.status !== "COMPLETED" ? (
                  <Form.Select
                    value={statusMap[reservation.id] || ""}
                    onChange={(e) =>
                      handleStatusChange(reservation.id, e.target.value)
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="REJECTED">Rejected</option>
                    <option value="RESERVED">Reserved</option>
                  </Form.Select>
                ) : (
                  <span>No actions available</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Status Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to update the reservation status?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmStatusChange}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ReservationTable;
