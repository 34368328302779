import api from "../api";
import { useQuery } from "react-query";

export const useGetPropertyById = (id) => {
  return useQuery(["propertybyID", id], () => {
    if (id) {
      return api.get(`property/${id}`).then((res) => res.data);
    }
  });
};

export const useGetPropertyCommentById = (id, commentId) => {
  return useQuery(["commentById"], () => {
    return api
      .get(`property/${id}/comment/${commentId}`)
      .then((res) => res.data);
  });
};

export const useGetPropertyDocumentByPropertyId = (id) => {
  return useQuery(["PropertyDocumentByPropertyId"], () => {
    return api.get(`property/${id}/get-document`).then((res) => res.data);
  });
};
