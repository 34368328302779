import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import UnauthorizedPage from "../../components/UnauthorizedPage";
import { GetRoleNavigationPath } from "../../routeUtils";
import Dao from "../Dao";
import PropertyType from "../PropertyTypes";
import UserKycs from "../UserKycSubmmited";
import AdminRequest from "./AdminRequests";
import ListProperty from "./ListProperty";
import GenerateUserPage from "../GenerateUserPage";
import Calendar from "./Calendar";

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState("listproperty");
  const roleUrl = GetRoleNavigationPath();
  const renderComponent = () => {
    switch (activeTab) {
      case "listproperty":
        if (
          roleUrl === "superadmin" ||
          roleUrl === "admin" ||
          roleUrl === "manager"
        ) {
          return <ListProperty />;
        } else {
          return <UnauthorizedPage />;
        }
      case "dao":
        if (roleUrl === "superadmin") {
          return <Dao />;
        } else {
          return <UnauthorizedPage />;
        }
      case "sell/request":
        if (roleUrl === "superadmin" || roleUrl === "admin") {
          return <AdminRequest />;
        } else {
          return <UnauthorizedPage />;
        }

      case "propertytype":
        if (roleUrl === "superadmin") {
          return <PropertyType />;
        } else {
          return <UnauthorizedPage />;
        }
      case "ratecalendar":
        if (roleUrl === "superadmin") {
          return <Calendar />;
        } else {
          return <UnauthorizedPage />;
        }

      case "generateuser":
        if (process.env.REACT_APP_IS_PRODUCTION === "false") {
          if (
            roleUrl === "superadmin" ||
            roleUrl === "admin" ||
            roleUrl === "manager"
          ) {
            return <GenerateUserPage />;
          } else {
            return <UnauthorizedPage />;
          }
        } else {
          return (
            <p className="text-carre-red mt-2 w-100 fs-4 fw-semibold text-center">
              You cant take this action in production
            </p>
          );
        }

      case "usersubmittingkyc":
        if (roleUrl === "superadmin" || roleUrl === "admin") {
          return <UserKycs />;
        } else {
          return <UnauthorizedPage />;
        }

      default:
        return null;
    }
  };
  return (
    <div className="">
      <Navbar style={{ height: "auto", zIndex: "0" }}>
        <Nav className="mt-4 gap-2 rounded-0 overflow-auto mx-2">
          <Nav.Link
            href="#sell/request"
            active={activeTab === "sell/request"}
            onClick={() => setActiveTab("sell/request")}
            style={{
              borderBottom:
                activeTab === "sell/request" ? "4px solid #007bff" : "none",
              color: activeTab === "sell/request" ? "#007bff" : "#000",
              whiteSpace: "nowrap",
            }}
          >
            <span className="h6">Requests</span>
          </Nav.Link>
          <Nav.Link
            href="#listproperty"
            active={activeTab === "listproperty"}
            onClick={() => setActiveTab("listproperty")}
            style={{
              borderBottom:
                activeTab === "listproperty" ? "4px solid #007bff" : "none",
              color: activeTab === "listproperty" ? "#007bff" : "#000",
              whiteSpace: "nowrap",
            }}
          >
            <span className="h6">List Property</span>
          </Nav.Link>
          <Nav.Link
            href="#dao"
            active={activeTab === "dao"}
            onClick={() => setActiveTab("dao")}
            style={{
              borderBottom: activeTab === "dao" ? "4px solid #007bff" : "none",
              color: activeTab === "dao" ? "#007bff" : "#000",
              whiteSpace: "nowrap",
            }}
          >
            <span className="h6">Dao</span>
          </Nav.Link>
          <Nav.Link
            href="#propertytype"
            active={activeTab === "propertytype"}
            onClick={() => setActiveTab("propertytype")}
            style={{
              borderBottom:
                activeTab === "propertytype" ? "4px solid #007bff" : "none",
              color: activeTab === "propertytype" ? "#007bff" : "#000",
              whiteSpace: "nowrap",
            }}
          >
            <span className="h6">Property type</span>
          </Nav.Link>
          {/*<Nav.Link*/}
          {/*  href="#usersubmittingkyc"*/}
          {/*  active={activeTab === "usersubmittingkyc"}*/}
          {/*  onClick={() => setActiveTab("usersubmittingkyc")}*/}
          {/*  style={{*/}
          {/*    borderBottom:*/}
          {/*      activeTab === "usersubmittingkyc"*/}
          {/*        ? "4px solid #007bff"*/}
          {/*        : "none",*/}
          {/*    color: activeTab === "usersubmittingkyc" ? "#007bff" : "#000",*/}
          {/*    whiteSpace: "nowrap",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <span className="h6">Users KYC</span>*/}
          {/*</Nav.Link>*/}
          <Nav.Link
            href="#generateuser"
            active={activeTab === "generateuser"}
            onClick={() => setActiveTab("generateuser")}
            style={{
              borderBottom:
                activeTab === "generateuser" ? "4px solid #007bff" : "none",
              color: activeTab === "generateuser" ? "#007bff" : "#000",

              whiteSpace: "nowrap",
            }}
          >
            <span className="h6">User Generation</span>
          </Nav.Link>
          <Nav.Link
            href="#ratecalendar"
            active={activeTab === "ratecalendar"}
            onClick={() => setActiveTab("ratecalendar")}
            style={{
              borderBottom:
                activeTab === "ratecalendar" ? "2px solid #007bff" : "none",
              color: activeTab === "ratecalendar" ? "#007bff" : "#000",

              whiteSpace: "nowrap",
            }}
          >
            <span className="h6">Rate Calendar</span>
          </Nav.Link>
        </Nav>
      </Navbar>

      <hr />
      {renderComponent()}
    </div>
  );
};

export default AdminDashboard;
