import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";

import Modal from "react-bootstrap/Modal";
import signup from "../assets/img/signup.png";
import YesIcon from "../assets/icons/YesIcon";
import Logo from "../components/Logo";
import { useMutation } from "react-query";
import { createUser } from "../hooks/CreateUser";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { AiOutlineGoogle } from "react-icons/ai";
import { useReferal } from "../hooks/useReferal";
import { useNavigate } from "react-router-dom";

function SignUp(props) {
  const [usCitizen, setUsCitizen] = useState(true);

  const [cookies, setCookies, removeCookie] = useCookies();
  const [response, setResponse] = useState({});
  const nav = useNavigate();

  const { mutate: referalMutation } = useMutation(useReferal, {
    onSuccess: (data) => {
      localStorage?.removeItem("ref");
      nav("/");
    },
  });
  const storedRef = localStorage?.getItem("ref");
  const { mutate, isError, error, isLoading } = useMutation(createUser, {
    onSuccess: (data) => {
      setResponse(data);
      setCookies("email", data.user.email);
      setCookies("verified", data.user.verified);
      props.onHide();
      props.showVerfiyUser();
      if (storedRef !== null && storedRef !== undefined) {
        referalMutation({ referral_name: storedRef, email: data?.user.email });
      }
    },

    onError: (data) => {
      setResponse(data);

      const errorMessage = data.response.data.error || "An error occurred.";
      toast.error(errorMessage); // Display error using react-toastify
    },
  });

  const { register, handleSubmit, setValue } = useForm();
  const onSubmit = (data) => {
    const updatedData = {
      ...data,
      american_citizen: !data.american_citizen,
    };
    mutate(updatedData);
  };

  const password = useRef();
  const cPassword = useRef();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);

  useEffect(() => {
    if (isCPasswordDirty) {
      if (password.current.value === cPassword.current.value) {
        setShowErrorMessage(false);
      } else {
        setShowErrorMessage(true);
      }
    }
  }, [isCPasswordDirty]);

  const checkPasswords = (e) => {
    setValue("password", password.current.value);

    if (e.target === cPassword.current) {
      // setIsCPasswordDirty(true);
      setTimeout(() => {
        setIsCPasswordDirty(true);
      }, 1500);
    }
    if (isCPasswordDirty) {
      if (password.current.value === cPassword.current.value) {
        setShowErrorMessage(false);
        setValue("password", password.current.value);
      } else {
        setShowErrorMessage(true);
      }
    }
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen="lg-down"
      aria-hidden="true"
    >
      <Modal.Header closeButton>
        {" "}
        <Logo />
      </Modal.Header>
      <Modal.Body className="">
        <div className="">
          <div className="bg-white row">
            <div className="col-5 border-1 d-lg-block d-none">
              <div className="m-1">
                <h4 className={"fw-semibold"}>Join Carré.</h4>
                <h4 className={"fw-semibold"}>Get premium benefits:</h4>
              </div>
              <div>
                <div className={"d-flex align-items-center m-2"}>
                  <YesIcon /> <span>Buy property shares in a few clicks</span>
                </div>
                <div className={"d-flex align-items-center m-2"}>
                  <YesIcon /> <span>Easily withdraw your rental income</span>
                </div>
                <div className={"d-flex align-items-center m-2"}>
                  <YesIcon /> <span>Vote on important decisions</span>
                </div>
              </div>
              <br />

              <div className="">
                <img src={signup} width={280} height={250} className={"px-3"} />
              </div>
              <br />
              <p>
                Already have an account?{" "}
                <button
                  style={{
                    border: "none",
                    background: "none",
                    textDecoration: "underline",
                  }}
                  className={"text-carre-blue"}
                  onClick={props.openLoginFromSignUp}
                >
                  Sign in
                </button>
              </p>
            </div>

            <div className="col-12 col-lg-7">
              <div
                className="d-flex justify-content-center align-items-center mh-100"
                // style={{ height: "600px" }}
              >
                <div>
                  <form
                    action={
                      process.env.REACT_APP_API_KEY + "/google-auth/login"
                    }
                    method="get"
                  >
                    <Button
                      type="submit"
                      variant="outline-carre-purple"
                      className={"w-100 mb-3 d-flex justify-content-center"}
                    >
                      <AiOutlineGoogle
                        style={{ width: "20", height: "20" }}
                        className={"m-1"}
                      />
                      <span style={{ marginTop: "1.5px" }}>
                        Sign in with Google
                      </span>
                    </Button>
                  </form>
                  <div className="py-1 mb-3 d-flex align-items-center">
                    <hr className="w-100" />
                    <div className="px-3">Or</div>
                    <hr className="w-100" />
                  </div>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    {isError ? (
                      <p className="text-carre-red">
                        {response?.response?.data?.message}
                      </p>
                    ) : (
                      <p></p>
                    )}
                    <Form.Group controlId="si-name" className="mb-2">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        size="sm"
                        {...register("first_name")}
                        type="string"
                        placeholder="Enter your first name"
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="si-name" className="mb-2">
                      <Form.Label className="fs-sm">Last Name</Form.Label>
                      <Form.Control
                        size="sm"
                        {...register("last_name")}
                        type="string"
                        placeholder="Enter your last name"
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="si-email" className="mb-2">
                      <Form.Label className="fs-sm">Email</Form.Label>
                      <Form.Control
                        size="sm"
                        {...register("email")}
                        type="string"
                        placeholder="Enter your email"
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <div className={"d-flex justify-content-between mb-2"}>
                        <Form.Label
                          htmlFor="si-password"
                          className="mb-0 d-inline fs-sm"
                        >
                          Password
                        </Form.Label>
                      </div>
                      <Form.Control
                        size="sm"
                        {...register("password")}
                        onChange={checkPasswords}
                        ref={password}
                        placeholder="Enter password"
                        className={
                          showErrorMessage && isCPasswordDirty
                            ? "border border-carre-red"
                            : ""
                        }
                        type="password"
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <div className={"d-flex justify-content-between mb-2"}>
                        <Form.Label
                          htmlFor="si-password"
                          className="mb-0 d-inline fs-sm"
                        >
                          Confirm Password
                        </Form.Label>
                      </div>
                      <Form.Control
                        size="sm"
                        className={
                          showErrorMessage && isCPasswordDirty
                            ? "border border-carre-red"
                            : ""
                        }
                        type="password"
                        placeholder="Enter confirmation password"
                        onChange={checkPasswords}
                        ref={cPassword}
                        required
                      />
                    </Form.Group>
                    {showErrorMessage && isCPasswordDirty ? (
                      <p className="text-danger ">Passwords did not match!</p>
                    ) : (
                      ""
                    )}
                    <Form.Group
                      controlId="si-us-citizen"
                      className="mb-2 fs-sm"
                    >
                      <Form.Check
                        {...register("american_citizen")}
                        type="checkbox"
                        label="I certify that I am not a Citizen or a Resident of the United States of America"
                        checked={usCitizen}
                        onChange={(e) => setUsCitizen(e.target.checked)}
                      />
                    </Form.Group>
                    <Button
                      type={"submit"}
                      size="lg"
                      variant="carre-blue w-100"
                      disabled={
                        (showErrorMessage && isCPasswordDirty) || isLoading
                      }
                    >
                      {!isLoading ? "Sign up" : "Please wait"}
                    </Button>
                  </Form>
                  <p className="d-lg-none d-block">
                    Already have an account?
                    <button
                      style={{
                        border: "none",
                        background: "none",
                        textDecoration: "underline",
                      }}
                      className="text-carre-blue"
                      onClick={props.openLoginFromSignUp}
                    >
                      Sign in
                    </button>
                  </p>
                </div>
                {/*<Nav.Link href="/create">Dont have an Account,Create One!</Nav.Link>*/}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SignUp;
