import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./RangeDatePicker.css";
import { useGetPropertyForReservation } from "../hooks/useGetWalletStats";
import ReservationPropertyCard from "./ReservationPropertyCard";
import { Col, Row, Alert, Form, InputGroup, Button } from "react-bootstrap";
import { FaCalendarAlt } from "react-icons/fa";
import { useGetVacationDetails } from "../hooks/useGetVacationDetails";

const RangeDatePicker = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [showAlert, setShowAlert] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const addTwoHoursAndFormat = (date) => {
    if (!date) return null;
    const newDate = new Date(date.getTime() + 2 * 60 * 60 * 1000);
    return newDate.toISOString().split("T")[0];
  };

  const formattedStartDate = addTwoHoursAndFormat(startDate);
  const formattedEndDate = addTwoHoursAndFormat(endDate);
  const { data, isLoading, error } = useGetPropertyForReservation(
    isSearching ? formattedStartDate : null,
    isSearching ? formattedEndDate : null
  );
  const { data: vacationData } = useGetVacationDetails();

  useEffect(() => {
    if (!isLoading && data?.length === 0 && isSearching) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [data, isLoading, isSearching]);

  const handleSearch = () => {
    if (startDate && endDate) {
      setIsSearching(true);
    }
  };

  return (
    <div className="range-picker-wrapper">
      <div className="range-picker-container justify-content-start">
        <DatePicker
          selected={startDate}
          onChange={(update) => setDateRange(update)}
          startDate={startDate}
          endDate={endDate}
          minDate={new Date()}
          selectsRange
          placeholderText="Check-in — Check-out"
          className="range-picker-input"
          monthsShown={window.innerWidth <= 768 ? 1 : 2}
          dateFormat="MMM d"
          customInput={
            <CustomInput
              startDate={startDate}
              endDate={endDate}
              addTwoHoursAndFormat={addTwoHoursAndFormat}
            />
          }
        />
        <div className="mt-2 d-md-block mx-2  d-none w-50">
          <Button
            variant="primary"
            className="fw-semibold mt-4 w-50"
            size="sm"
            onClick={handleSearch}
            disabled={!startDate || !endDate}
          >
            Search
          </Button>
        </div>
      </div>
      <div className="mt-2 d-md-none">
        <Button
          variant="primary"
          className="fw-semibold mt-4  w-100"
          size="sm"
          onClick={handleSearch}
          disabled={!startDate || !endDate}
        >
          Search
        </Button>
      </div>

      {showAlert && (
        <Alert variant="warning" className="mt-3">
          No properties available for the selected dates.
        </Alert>
      )}

      <Row className="g-6 mt-5">
        <Col xs={12} className="d-md-none">
          <p className="text-medium fw-medium">
            <strong>{vacationData?.balance.toLocaleString()}</strong> vacation
            point available
          </p>
        </Col>
        {data?.map((property) => (
          <Col key={property.property_id} sm={12} md={6} lg={4}>
            <ReservationPropertyCard
              property={property}
              startDate={formattedStartDate}
              endDate={formattedEndDate}
            />
          </Col>
        ))}
      </Row>

      <div className="vacation-points-mobile d-none d-md-block">
        <p className="text-medium fw-medium">
          <strong>{vacationData?.balance.toLocaleString()}</strong> vacation
          point available
        </p>
      </div>
    </div>
  );
};

const CustomInput = React.forwardRef(
  ({ value, onClick, startDate, endDate, addTwoHoursAndFormat }, ref) => (
    <Row className="gap-3 gap-md-0 custom-hover justify-content-center">
      <Col xs={12} md={6}>
        <Form.Group>
          <Form.Label>Start Date</Form.Label>
          <InputGroup
            size="sm"
            className="input-group-custom"
            onClick={onClick}
            ref={ref}
          >
            <Form.Control
              type="text"
              value={startDate ? addTwoHoursAndFormat(startDate) : ""}
              placeholder="Select Start Date"
              readOnly
              className="input-no-border"
            />
            <InputGroup.Text>
              <FaCalendarAlt className="text-gray-500" />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col xs={12} md={6}>
        <Form.Group>
          <Form.Label>End Date</Form.Label>
          <InputGroup
            size="sm"
            className="input-group-custom"
            onClick={onClick}
            ref={ref}
          >
            <Form.Control
              type="text"
              value={endDate ? addTwoHoursAndFormat(endDate) : ""}
              placeholder="Select End Date"
              readOnly
              className="input-no-border"
            />
            <InputGroup.Text className="">
              <FaCalendarAlt className="text-gray-500" />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
    </Row>
  )
);

export default RangeDatePicker;
