import axios from "axios";

const cookies = document.cookie.split("; ");
const api = axios.create({
  baseURL: process.env.REACT_APP_API_KEY || "http://localhost:9990",
});

const parseCookie = () => {
  const cookies = document.cookie.split("; ");
  const parsedCookie = {};
  cookies.forEach((pair) => {
    const [key, value] = pair.split("=");
    parsedCookie[key] = value;
  });
  return parsedCookie;
};

api.interceptors.request.use(
  async (req) => {
    const parsedCookie = parseCookie();
    if (parsedCookie?.token) {
      req.headers["Authorization"] = `Bearer ${parsedCookie.token}`;
    }
    return req;
  },
  (error) => Promise.reject(error)
);

export default api;
